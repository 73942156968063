
<template>
    <form class="register-user otp-form">
        <div class="sub-title">
            Chúng tôi đã gửi mã OTP để xác nhận tài khoản của bạn qua số điện thoại 
            <span v-text="phoneNumber"></span>
        </div>
        <div class="otp-group">   
            <input
                v-if="inputType === 'password'"
                autocomplete="off"
                name="hidden"
                type="text"
                style="display: none"
            />
            <SingleOtpInput
                v-for="(item, i) in numInputs"
                :key="i"
                :focus="activeInput === i"
                :value="otp[i]"
                :separator="separator"
                :input-type="inputType"
                :input-classes="inputClasses"
                :is-last-child="i === (numInputs - 1)"
                :should-auto-focus="shouldAutoFocus"
                :placeholder="placeholder[i]"
                :is-disabled="isDisabled"
                @on-change="handleOnChange"
                @on-keydown="handleOnKeyDown"
                @on-paste="handleOnPaste"
                @on-focus="handleOnFocus(i)"
                @on-blur="handleOnBlur"
            />
        </div>
        
        <p class="dosiin_text-center dosiin_mb-2 dosiin_mt-2">
            <pulse-loader v-if="bindingLoading" :loading="bindingLoading" color="#000000"></pulse-loader>
        </p>

        <p class="time dosiin_text-center">
            <vue-countdown @end="onCountdownEnd" :time="timer * 1000" v-slot="{ seconds }">
                Thời gian còn lại
                <span> 00 : {{ _addLeadingZero(seconds) }}</span> giây
            </vue-countdown>
        </p>

        <a v-if="showButtonResend" @click="$emit('on-failed' , 1)" class="send-again">Không nhận được mã ? Gửi lại OTP</a>
    </form>
</template>

<script>
    import {  ref  , computed} from "vue";
    import SingleOtpInput from "./SingleOtpInput.vue";
    import { useRouter } from 'vue-router';
    import { useToast } from "vue-toastification";
    // keyCode constants
    const BACKSPACE = 8;
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    const DELETE = 46;
    export default {
        name: "Vue3OtpInput", // vue component name
        components: {
            SingleOtpInput,
        },
        props: {
            numInputs: {
                default: 4,
            },
            separator: {
                type: String,
                default: "**",
            },
            inputClasses: {
                type: String,
            },
            inputType: {
                type: String,
                validator: (value) =>
                    ["number", "tel", "password"].includes(value),
            },
            shouldAutoFocus: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: Array,
                default: [],
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            phone : {
                type: String,
                default: '',
            },
            timer: {
                type : Number,
                default : 50
            },
            loading : {
                type : Boolean,
                default : false
            },
            modelValue:Boolean
        },
        setup(props, { emit }) {
           
            const activeInput = ref(0);
            const otp         = ref([]);
            const oldOtp      = ref([]);
            const phoneNumber = props.phone;
            const bindingLoading = computed({
                get: () => props.modelValue
                // set: (value) => emit("update:modelValue", value),
            });
            
            const timer       = props.timer;
            const router      = useRouter();
            const toast       = useToast();
            const showButtonResend = ref(false);
            const handleOnFocus = (index) => {
                activeInput.value = index;
            };
            const handleOnBlur = () => {
                activeInput.value = -1;
            };
            // Helper to return OTP from input
            const checkFilledAllInputs = () => {
                if (otp.value.join("").length === props.numInputs) {
                    // complete otp
                    return emit("on-complete", otp.value.join(""));
                }

                return "Wait until the user enters the required number of characters";
            };
            // Focus on input by index
            const focusInput = (input) => {
                activeInput.value = Math.max(Math.min(props.numInputs - 1, input), 0);
            };
            // Focus on next input
            const focusNextInput = () => {
                focusInput(activeInput.value + 1);
            };
            // Focus on previous input
            const focusPrevInput = () => {
                focusInput(activeInput.value - 1);
            };
            // Change OTP value at focused input
            const changeCodeAtFocus = (value) => {
                oldOtp.value = Object.assign([], otp.value);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                otp.value[activeInput.value] = value;
                if (oldOtp.value.join("") !== otp.value.join("")) {
                    emit("on-change", otp.value.join(""));
                    checkFilledAllInputs();
                }
            };
            // Handle pasted OTP
            const handleOnPaste = (event) => {
                event.preventDefault();
                const pastedData = event.clipboardData
                    .getData("text/plain")
                    .slice(0, props.numInputs - activeInput.value)
                    .split("");
                if (props.inputType === "number" && !pastedData.join("").match(/^\d+$/)) {
                    return "Invalid pasted data";
                }
                // Paste data from focused input onwards
                const currentCharsInOtp = otp.value.slice(0, activeInput.value);
                const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
                combinedWithPastedData
                    .slice(0, props.numInputs)
                    .forEach(function (value, i) {
                    otp.value[i] = value;
                });
                focusInput(combinedWithPastedData.slice(0, props.numInputs).length);
                return checkFilledAllInputs();
            };

            const handleOnChange = (value) => {
                changeCodeAtFocus(value);
                focusNextInput();
            };


            const clearInput = () => {
                if (otp.value.length > 0) {
                    emit("on-change", "");
                }
                otp.value = [];
                activeInput.value = 0;
            };
            // Handle cases of backspace, delete, left arrow, right arrow
            const handleOnKeyDown = (event) => {
                switch (event.keyCode) {
                    case BACKSPACE:
                        event.preventDefault();
                        changeCodeAtFocus("");
                        focusPrevInput();
                        break;
                    case DELETE:
                        event.preventDefault();
                        changeCodeAtFocus("");
                        break;
                    case LEFT_ARROW:
                        event.preventDefault();
                        focusPrevInput();
                        break;
                    case RIGHT_ARROW:
                        event.preventDefault();
                        focusNextInput();
                        break;
                    default:
                        break;
                }
            };

            // countdown 
            const onCountdownEnd = () => {
                showButtonResend.value = true;
                toast.warning('Mã OTP đã hết hạn , vui lòng nhập lại');
            }

            return {
                activeInput,
                bindingLoading,
                otp,
                oldOtp,
                phoneNumber,
                clearInput,
                timer,
                handleOnPaste,
                handleOnKeyDown,
                handleOnBlur,
                changeCodeAtFocus,
                focusInput,
                focusNextInput,
                focusPrevInput,
                handleOnFocus,
                checkFilledAllInputs,
                handleOnChange,
                onCountdownEnd,showButtonResend
            };
        }
    };
</script>
